(function($) { 

    $.fn.rangeSlider = function(options) { 
        let instance; 

        options = $.extend({}, $.fn.rangeSlider.defaults, options);
        
        this.defaults = {
            sliderInputs: {
                start: null,
                end: null
            },
            ionPluginOptions: {
                prettify_enabled: true,
                prettify_separator: '.',
                prefix: "&euro; ",
                force_edges: true
            },
            placeholder: null
        };

        this.init = (element, options) => { 
            let ionOptions = $.extend({
                onStart: handleInit,
                onChange: handleChange,
                onFinish: updateInputs
            }, this.defaults.ionPluginOptions, options.ionPluginOptions)
            console.log(ionOptions);
            
            element.ionRangeSlider(ionOptions);

            instance = element.data('ionRangeSlider');
            
            options.sliderInputs.start.on('change', { input: 'start'}, updateSlider);
            options.sliderInputs.end.on('change', { input: 'end'}, updateSlider);

            if(options.placeholder !== null){ 
                options.placeholder.hide();
            }

            return this;
        };

        let handleInit = (data) => {
            // updateToArrow(data);
            // updateFromArrow(data);
            return updateInputs(data);
        };
    
        let handleChange = (data) => { 
            // updateToArrow(data);
            // updateFromArrow(data);
            return updateInputs(data);
        };
    
        let updateToArrow = (data) => { 
            
            const values = { 
                
                // Arrow values are the min and max values of the "left" attribute on the ::before pseudo element of this bubble.
                // this corrosponds to the "left" property of the .irs-from::before or the .irs-to::before.
                'arrowMinValue': 50,
                'arrowMaxValue': 85,
    
                // Data values are the min and max values where we need to start and stop updating the pseudo element's "left" attribute.
                // This corrosponds to the value that is selected in the range slider
                'dataMinValue': 92,
                'dataMaxValue': 100
            };
            
            // Get new arrow percentage
            updateArrow(data.to_percent, values, '--irs-to-bubble-arrow-left');
        };
    
        let updateFromArrow = (data) => { 
            const values = { 
                
                // Arrow values are the min and max values of the "left" attribute on the ::before pseudo element of this bubble.
                // this corrosponds to the "left" property of the .irs-from::before or the .irs-to::before.
                'arrowMinValue': 25,
                'arrowMaxValue': 50,
    
                // Data values are the min and max values where we need to start and stop updating the pseudo element's "left" attribute.
                // This corrosponds to the value that is selected in the range slider
                'dataMinValue': 0,
                'dataMaxValue': 4.5
            };
            
            // update the arrow
            updateArrow(data.from_percent, values, '--irs-from-bubble-arrow-left');        
        };
    
        let updateArrow = (currentPercentage, values, htmlVar) => {
            
            // Make sure we need to process
            if(currentPercentage > values.dataMaxValue || currentPercentage < values.dataMinValue) {
                document.documentElement.style.setProperty(htmlVar, `50%`);
                return;
            }
            
            // This is the current percentage of the currently selected value
            // But then in between the data min and max values
            // If this number is > 100 or < 0 we don't need to listen
            const currentPercentOfDataValues = (currentPercentage - values.dataMinValue) / (values.dataMaxValue - values.dataMinValue);
    
    
            // Now we need to translate the previous value into a percentage for the arrow's left attribute        
            // First we get the arrow's value range 
            const valueRange = values.arrowMaxValue - values.arrowMinValue;
    
            // Then we multiply the arrow's value range by the current data value percentage
            const arrowPercent = valueRange * currentPercentOfDataValues;
    
            // Add the min % to the newly calculated %
            const newArrowPercentage = values.arrowMinValue + arrowPercent;
    
            document.documentElement.style.setProperty(htmlVar, `${newArrowPercentage}%`);
        };
    
        let updateInputs = (data) => { 
            start = data.from;
            end = data.to;

            $inputStart = options.sliderInputs.start;
            $inputEnd = options.sliderInputs.end;

            if($inputStart.val() != start) { 
                $inputStart.val(start);
                // Alpine.store('filters').updateFilter('priceSlider', start);
                $inputStart[0].dispatchEvent(new CustomEvent('change', {fromSlider: true}));
            } else if($inputEnd.val() != end) { 
                $inputEnd.val(end);
                // Alpine.store('filters').updateFilter('priceSlider', end);
                $inputEnd[0].dispatchEvent(new CustomEvent('change', {fromSlider: true}));
            }
        };
    
        let updateSlider = (event) => { 
            if(typeof event.data.fromSlider !== undefined || event.data.fromSlider === true) { 
                return;
            }
            let value = $(event.target).val();
            
            let currentInput = event.data.input;
            let otherInput = currentInput === 'start' ? options.sliderInputs.end : options.sliderInputs.start;
            let update;

            if(currentInput === 'start') { 
                value = value < instance.min ? instance.min : value;
                value = value > otherInput.val() ? parseInt(otherInput.val()) - 1 : value;
                value = value > instance.max ? instance.max : value;
                update = {from: value};
            } else if (currentInput === 'end') { 
                value = value < instance.min ? instance.min : value;
                value = value < otherInput.val() ? parseInt(otherInput.val()) + 1 : value;
                value = value > instance.max ? instance.max : value;
                update = {to: value};
            }

            instance.update(update);
            $(event.target).prop('value', value);
        }

        this.init($(this), options);
    }    
}(jQuery));
