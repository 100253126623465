import * as bootstrap from "bootstrap";
import "ion-rangeslider";
import Lightbox from 'bs5-lightbox';
// import "bs5-lightbox";

const opts = { 
    keyboard: true,
    size: 'fullscreen'
};

$(function(){
    $('[data-lightbox="lightbox"]').each(function(key, el){
        $(el).on('click', function(e){ 
            e.preventDefault();
            const lightbox = new Lightbox(el, opts);
            lightbox.show();
        });
    });
})
