// Import jQuery and Alpine
import jQuery from 'jquery';
// import Alpine from 'alpinejs';

// // Register Alpine in the window
// window.Alpine = Alpine;

// // Create the alpine stores
// import '../stores/filters';

// // Start alpine
// Alpine.start();

// Register jquery to the window object
window.jQuery = jQuery;
window.$ = jQuery;

//SEE https://github.com/alpinejs/alpine/pull/2063
// This change would allow to enable compatibility with jQuery event system
// document.addEventListener('alpine:init', () => {
//   Alpine.setListenerManipulators(
//     (target, event, handler, options, modifiers) => {
//       $(target).on(event, handler);
//     },
//     (target, event, handler, options) => {
//       $(target).off(event, handler);
//     }
//   );
// });
